import colors from "../../../constants/colors";
import "../../../styles/CustomButton.css";

interface ICustomButton {
	title: string;
	secondary?: boolean;
	width?: string;
	isloading?: boolean;
	disabled?: boolean;
	onClick: any;
	type?: "button" | "submit" | "reset";
}

export default function CustomButton(props: ICustomButton) {
	const {
		title,
		width = "90%",
		secondary = false,
		isloading = false,
		disabled,
		onClick,
		type = "button",
	} = props;
	return (
		<button
			type={type}
			disabled={disabled || isloading}
			className={!secondary ? "btn" : "btn-secondary"}
			onClick={onClick}
			style={{
				width: width,
			}}
		>
			{title}
		</button>
	);
}
