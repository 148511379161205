import { useNavigate } from "react-router-dom";
import "../../../styles/Card.css";
import CustomButton from "../forms/CustomButton";

interface ICustomCard {
	title: string;
	description: string;
	image: string;
	category: string;
}

export default function CustomCard(props: ICustomCard) {
	const { title, description, image, category } = props;
	const navigate = useNavigate();

	return (
		<div className="card">
			<div className="content-card">
				<h1 className="title-card">{title}</h1>
				<p className="description">{description}</p>
				<CustomButton
					width="300px"
					title="Saber más"
					onClick={() =>
						navigate("/service/details", {
							state: {
								title,
								category,
							},
						})
					}
				></CustomButton>
			</div>
			<div className="pic-container">
				<img src={image} alt={title} className="picture" />
			</div>
		</div>
	);
}
