import { Grid, GridProps } from "@mui/material";
import { ReactNode } from "react";

interface IGridResponsive extends GridProps {
	children: ReactNode;
	xs?: any;
	md?: any;
	withoutMargin?: boolean;
}

export default function GridResponsive(props: IGridResponsive) {
	const { children, xs = 12, md = 12, withoutMargin = false } = { ...props };
	return (
		<Grid
			{...props}
			item
			xs={xs}
			md={md}
			style={{
				display: "flex",
				justifyContent: "center",
				marginTop: withoutMargin ? "0" : "2%",
				alignSelf: "center",
			}}
		>
			{children}
		</Grid>
	);
}
