import { Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../commons/components/forms/CustomButton";
import CustomInput from "../../commons/components/forms/CustomInput";
import CustomTitle from "../../commons/components/forms/CustomTitle";
import GridResponsive from "../../commons/components/grid/GridResponsive";
import { IPaymentData, PaymentPlatform } from "../../commons/types/CommonTypes";
import useMPServices from "../mercadopago/hooks/useMPServices";
import usePayments from "./hooks/usePayments";
import { useMercadopago } from "react-sdk-mercadopago";
import env from "react-dotenv";
import "../../styles/Payment.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { paymentDataSchema } from "../../constants/helperts";

export default function PaymentCheckout() {
	const [alreadyGenerated, setAlreadyGenerated] = useState<boolean>(false);
	const [activePlatform, setActivePlatform] = useState<string>(
		PaymentPlatform.MERCADO_P
	);
	const navigate = useNavigate();
	const [paymentData, setPaymentData] = useState<IPaymentData>({
		name: "",
		email: "",
		docId: "",
		phoneNumber: 0,
		ammount: 0,
	});
	const { createTransactionNumber } = usePayments();
	const { createPreference } = useMPServices();
	const mercadopago = useMercadopago.v2(env.MP_KEY, {
		locale: "es-CO",
	});

	const {
		handleSubmit,
		register,
		reset,
		formState: { errors, isSubmitting },
	} = useForm({
		defaultValues: {
			name: "",
			email: "",
			phoneNumber: "",
			docId: "",
			ammount: 0,
		},
		mode: "onBlur",
		resolver: yupResolver(paymentDataSchema),
	});

	const createWompiForm = () => {
		let transactionId = createTransactionNumber("transactions");
		localStorage.setItem("paymentData", JSON.stringify(paymentData));
		if (!alreadyGenerated) {
			const script: any = document.createElement("script");
			if (script !== null) {
				script.src = "https://checkout.wompi.co/widget.js";
				script.setAttribute("data-render", "button");
				script.setAttribute(
					"data-public-key",
					"pub_test_nkPWFd8tPOqK8JZ9p0EHFpzpJ1ZZ2pxs"
				);
				script.setAttribute("data-currency", "COP");
				script.setAttribute(
					"data-ammount-in-cents",
					paymentData.ammount * 100
				);
				script.setAttribute("data-reference", transactionId);
				script.setAttribute(
					"data-redirect-url",
					"https://derechospersonastrans.com/checkout/"
				);
				script.setAttribute(
					"data-customer-data:email",
					paymentData.email
				);
				script.setAttribute(
					"data-customer-data:full-name",
					paymentData.name
				);
				script.setAttribute(
					"data-customer-data:phone-number",
					paymentData.phoneNumber
				);
				script.setAttribute(
					"data-customer-data:phone-number-prefix",
					"+57"
				);
				script.setAttribute(
					"data-customer-data:legal-id",
					paymentData.docId
				);
				script.setAttribute("data-customer-data:legal-id-type", "CC");
				let container: any =
					document.getElementById("container-checkout");
				if (container && !container.hasChildNodes()) {
					container.appendChild(script);
				}
				setAlreadyGenerated(true);
			}
		}
	};

	const createMercadoPagoForm = (paymentData: IPaymentData) => {
		localStorage.setItem("platform", PaymentPlatform.MERCADO_P);
		createPreference(paymentData, function (success: boolean, res: any) {
			if (success) {
				if (mercadopago) {
					const checkout = mercadopago.checkout({
						preference: {
							id: res.id,
						},
						autoOpen: true,
						render: {
							container: ".cho-container",
							label: "Paga con Mercado Pago",
						},
					});
				}
			} else {
				console.log("ERROR");
			}
			setAlreadyGenerated(success);
		});
	};

	const onValidSubmit = (values: any) => {
		activePlatform === PaymentPlatform.WOMPI
			? createWompiForm()
			: createMercadoPagoForm(values);
	};

	return (
		<div className="main-container">
			{!alreadyGenerated && (
				<div className="form-container">
					<CustomTitle>Pagos de forma segura</CustomTitle>
					<Typography variant="h5">Datos para el pago</Typography>

					<form onSubmit={handleSubmit(onValidSubmit)}>
						<div className="inner-form">
							<label htmlFor="name">Nombre</label>
							<input
								id="name"
								{...register("name")}
								maxLength={50}
							/>
							{errors.name && <p>{errors.name.message}</p>}
							<label htmlFor="email">Email</label>
							<input
								id="email"
								type="email"
								{...register("email")}
								maxLength={150}
							/>
							{errors.email && <p>{errors.email.message}</p>}
							<label htmlFor="docId">
								Documento de Identidad
							</label>
							<input
								id="docId"
								{...register("docId")}
								maxLength={12}
							/>
							{errors.docId && <p>{errors.docId.message}</p>}
							<label htmlFor="phoneNumber">
								Número de Teléfono
							</label>
							<input
								id="phoneNumber"
								{...register("phoneNumber")}
								maxLength={10}
							/>
							{errors.phoneNumber && (
								<p>{errors.phoneNumber.message}</p>
							)}
							<label htmlFor="ammount">Monto</label>
							<input
								id="ammount"
								{...register("ammount")}
								placeholder="$ 100.000"
								maxLength={10}
							/>
							{errors.ammount && <p>{errors.ammount.message}</p>}
							<CustomButton
								type="submit"
								title="Ir a pagar"
								width="100%"
								disabled={
									isSubmitting ||
									Object.keys(errors).length > 0
								}
								onClick={() => {}}
							/>
						</div>
					</form>
				</div>
			)}
			{alreadyGenerated && (
				<>
					<GridResponsive>
						<CustomTitle>Selecciona un medio de pago</CustomTitle>
					</GridResponsive>
					<GridResponsive>
						<Typography>
							{activePlatform === PaymentPlatform.WOMPI
								? "	Tarjetas Débito / Crédito / Nequi / Cuentas Bancolombia"
								: "Tarjetas Débito / Crédito / Mercado Pago"}
						</Typography>
					</GridResponsive>
				</>
			)}
			<GridResponsive>
				{activePlatform === PaymentPlatform.WOMPI ? (
					<form id="container-checkout" />
				) : (
					<div className="cho-container" />
				)}
			</GridResponsive>

			{alreadyGenerated && (
				<GridResponsive>
					<CustomButton
						title="Cancelar pago"
						secondary
						onClick={() => navigate("/")}
					/>
				</GridResponsive>
			)}
		</div>
	);
}
