import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "../../modules/auth/Login";
import BlogViewer from "../../modules/blog/BlogViewer";
import CreateBlog from "../../modules/blog/CreateBlog";
import DashBoard from "../../modules/dashboard/AdminDashboard";
import ResponseCheckout from "../../modules/payments/components/ResponseCheckout";
import PaymentCheckout from "../../modules/payments/PaymentCheckout";
import ServiceDetail from "../../modules/services/components/ServiceDetail";
import AppBarMenu from "../menu/AppBarMenu";
import PrivateRoute from "./PrivateRoute";
import Home from "../../modules/Home/Home";

export default function Navigation() {
	return (
		<BrowserRouter>
			<AppBarMenu />
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/blog/:id" element={<BlogViewer />} />
				<Route path="/login" element={<Login />} />
				<Route
					path="/dashboard"
					element={
						<PrivateRoute>
							<DashBoard />
						</PrivateRoute>
					}
				/>
				<Route
					path="/create/:id"
					element={
						<PrivateRoute>
							<CreateBlog />
						</PrivateRoute>
					}
				></Route>
				<Route path="/pagos" element={<PaymentCheckout />} />
				<Route path="/checkout" element={<ResponseCheckout />} />
				<Route path="/service/details" element={<ServiceDetail />} />
			</Routes>
		</BrowserRouter>
	);
}
