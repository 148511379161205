import {
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from "@mui/material";
import { ReactNode } from "react";
import "../../../styles/CustomTable.css";
import colors from "../../../constants/colors";

interface IProps {
	item: any;
	setCurrentOption: any;
	currentOption: string;
	icon: ReactNode;
}

const CustomListItem = (props: IProps) => {
	const { setCurrentOption, currentOption, item, icon } = props;

	return (
		<ListItem
			disablePadding
			onClick={() => {
				setCurrentOption(item.label);
			}}
			className={currentOption === item.label ? "itemActive" : "item"}
		>
			<ListItemButton>
				<ListItemIcon>{icon}</ListItemIcon>
				<ListItemText primary={item.label} />
			</ListItemButton>
		</ListItem>
	);
};

export default CustomListItem;
