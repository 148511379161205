import { useEffect, useState } from "react";
import { IBlog } from "../types/CommonTypes";
import useFirebase from "./useFirebase";

export default function useBlogServices() {
	const {
		getData,
		getDocument,
		deleteDocumentById,
		createDocument,
		updateDocument,
		loading,
	} = useFirebase();
	const [blogList, setBlogList] = useState<IBlog[] | any[]>([]);
	const [blog, setBlog] = useState<IBlog>();

	useEffect(() => {
		fetchBlogs();
	}, []);

	const createBlog = async (blog: IBlog, callback: any) => {
		await createDocument(blog, callback);
	};

	const updateBlog = async (blog: IBlog, callback: any) => {
		await updateDocument(blog, `/blogs`, callback, blog.id);
	};

	const fetchBlogs = async () => {
		await getData("blogs", function (blogs: IBlog[] | any) {
			setBlogList(blogs.length ? blogs : []);
		});
	};

	const getBlogById = async (id: string) => {
		await getDocument("blogs", "id", id, function (blog: IBlog[]) {
			setBlog(blog[0]);
		});
	};

	const deleteBlog = async (id: string, picture?: any) => {
		id !== "" &&
			(await deleteDocumentById(
				"blogs",
				id,
				async function (succes: boolean) {
					if (succes) {
						await fetchBlogs();
					}
				},
				picture
			));
	};

	const editBlog = async (id: string, blok: IBlog, callback: any) => {};

	return {
		deleteBlog,
		getBlogById,
		editBlog,
		createBlog,
		updateBlog,
		blog,
		loading,
		blogList,
	};
}
