import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

interface ISnackBar {
	msg: string;
	open: boolean;
	error: boolean;
}

export default function SnackBar(props: ISnackBar) {
	const { msg, error, open } = props;
	return (
		<Snackbar open={open} autoHideDuration={4000} onClose={() => {}}>
			<Alert
				onClose={() => {}}
				severity={error ? "error" : "success"}
				sx={{ width: "100%" }}
			>
				{msg}
			</Alert>
		</Snackbar>
	);
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
	props,
	ref
) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
