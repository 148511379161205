import { useCallback, useEffect, useState } from "react";
import servicesList from "../../constants/data";

export default function useServices() {
	const [category, setCategory] = useState<string>("Personas Naturales");
	const [services, setServices] = useState<any[]>([]);

	useEffect(() => {
		filterServices();
	}, [category]);

	const filterServices = () => {
		setServices(servicesList[category] ?? []);
	};

	const getServiceByTitle = useCallback((category: string, title: string) => {
		console.log(category, title);
		return servicesList[category].find(
			(service) => service.title === title
		);
	}, []);

	return {
		services,
		loading: false,
		category,
		setCategory,
		getServiceByTitle,
	};
}
