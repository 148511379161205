import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LogoutIcon from "@mui/icons-material/Logout";
import Toolbar from "@mui/material/Toolbar";
import { useEffect, useState } from "react";
import { CircularProgress, Grid } from "@mui/material";
import useAuth from "../../commons/hooks/useAuth";
import { useNavigate } from "react-router-dom";
import DrawerChild from "./components/DrawerChild";
import colors from "../../constants/colors";
import { databaseMenu, menu } from "../../constants/helperts";
import CreateBlog from "../blog/CreateBlog";
import TableBlogs from "./components/TableBlogs";
import { IBlog } from "../../commons/types/CommonTypes";

const drawerWidth = 240;

interface Props {
	window?: () => Window;
}

export default function ResponsiveDrawer(props: Props) {
	const { window } = props;
	const [mobileOpen, setMobileOpen] = useState(false);
	const [currentOption, setCurrentOption] = useState("Entradas");
	const [itemToEdit, setItemToEdit] = useState<any>(null);
	const { handleLogOut } = useAuth();
	const navigate = useNavigate();
	const [isEditing, setIsEditing] = useState(false);

	useEffect(() => {
		if (!isEditing) {
			setItemToEdit(null);
		}
		setIsEditing(false);
	}, [currentOption, isEditing]);

	const resetState = (option?: string) => {
		if (option) {
			setCurrentOption(option);
		}
		setItemToEdit(null);
		setIsEditing(false);
	};

	const onEditItem = (item: any, option: string) => {
		setItemToEdit(item);
		setCurrentOption(option);
		setIsEditing(true);
	};

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const options = [
		{
			label: "Volver al inicio",
			icon: <ArrowBackIcon />,
			action: () => navigate("/"),
		},
		{
			label: "Cerrar Sesión",
			icon: <LogoutIcon />,
			action: async () => {
				await handleLogOut(() => {
					navigate("/");
				});
			},
		},
	];

	const container =
		window !== undefined ? () => window().document.body : undefined;

	return (
		<Box
			sx={{
				display: "flex",
				bgcolor: "white",
			}}
		>
			<Box
				component="nav"
				sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
				aria-label="mailbox folders"
			>
				<Drawer
					container={container}
					variant={"permanent"}
					open={mobileOpen}
					onClose={handleDrawerToggle}
					ModalProps={{
						keepMounted: true,
					}}
					sx={{
						"& .MuiDrawer-paper": {
							boxSizing: "border-box",
							width: drawerWidth,
							bgcolor: colors.PRIMARY,
							padding: 0,
						},
					}}
				>
					<DrawerChild
						currentOption={currentOption}
						databaseMenu={databaseMenu}
						drawerWidth={drawerWidth}
						handleDrawerToggle={handleDrawerToggle}
						setCurrentOption={setCurrentOption}
						menu={menu}
						options={options}
					/>
				</Drawer>
			</Box>
			{currentOption !== "" ? (
				<Box
					component="main"
					sx={{
						display: "flex",
						flexGrow: 1,
						p: 3,
						width: { sm: `calc(100% - ${drawerWidth}px)` },
						justifyContent: "center",
						height: "100%",
						marginTop: "80px",
					}}
				>
					<Toolbar />
					{currentOption === "Nuevo Artículo" && <CreateBlog />}
					{currentOption === "Entradas" && (
						<TableBlogs
							onEdit={(item: IBlog) =>
								onEditItem(item, "Nuevo Artículo")
							}
							newItemRef="Nuevo Artículo"
							onReset={resetState}
						/>
					)}
				</Box>
			) : (
				<Grid
					container
					sx={{
						marginTop: "20px",
						display: "flex",
						height: "100dvh",
						width: `90vw`,
						justifyContent: "center",
						alignItems: "center",
						alignContent: "center",
					}}
				>
					<CircularProgress
						color="primary"
						style={{ alignSelf: "center" }}
					/>
				</Grid>
			)}
		</Box>
	);
}
