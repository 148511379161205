import "../../styles/Banner.css";
import CustomButton from "./forms/CustomButton";

export default function Banner() {
	const openWhatsapp = () => {
		let url = `https://api.whatsapp.com/send/?phone=573123135227&text=Hola, me gustia obtener mas información acerca de `;
		window.open(url, "_blank");
	};

	return (
		<div className="banner">
			<div className="left-side">
				<h1 className="title-banner">Derechos Personas Trans</h1>
				<h4 className="description-banner">
					Te ofrecemos orientación jurídica de acuerdo con tus
					necesidades para que puedas ejercer tus derechos de forma
					plena y sin discriminación
				</h4>
				<div className="btn-container">
					<CustomButton
						title={"Contáctanos"}
						onClick={openWhatsapp}
						width="50%"
						secondary
					></CustomButton>
				</div>
			</div>
			<div className="right-side">
				<img
					className="picture-banner"
					src="https://firebasestorage.googleapis.com/v0/b/pdtrans-8631a.appspot.com/o/assets%2Fabout%2FSelf%20confidence-pana.png?alt=media&token=4d92a4c4-b698-4a48-91d8-9010022cf4d8"
				></img>
			</div>
		</div>
	);
}
