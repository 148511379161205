const faqs = [
	{
		id: 1,
		title: "¿Qué debo hacer para asistir a una asesoría jurídica? ",
		description: `Ponte en contacto con nosotros a través del servicio de whatsapp o al correo
			<a target="__blank" href="mailto:derechospersonastrans@gmail.com">
				derechospersonastrans@gmail.com
			</a> Uno de nuestros abogados te indicará cómo funcionan nuestros servicios y te agendará una cita. En la cita el abogad@ te orientará acerca del mejor mecanismo jurídico para hacer válidos tus derechos `,
	},
	{
		id: 2,
		title: "¿Quién presta los servicios?",
		description:
			"Los servicios son prestados por abogad@s especialistas en derechos humanos con experiencia profesional específica en diversidad sexual.",
	},
	{
		id: 3,
		title: "¿Cuánto tiempo dura la sesión de asesoría jurídica?",
		description:
			"La sesión de asesoría jurídica tiene una duración de 40 minutos a una hora, en donde se explicará a detalle las opciones que tiene la persona teniendo en cuenta sus circunstancias específicas",
	},
	{
		id: 4,
		title: "¿Qué sucede una vez se realiza la asesoría jurídica?",
		description:
			"La persona conocerá cual es el mecanismo jurídico que mejor se adapta a sus necesidades y en caso de que quiera que el abogad@ continúe el proceso, se le indicará el tiempo de elaboración de la acción jurídica, así como el valor de la misma",
	},
	{
		id: 5,
		title: "¿Dónde están ubicados?",
		description:
			"Estamos ubicados en la ciudad de Bogotá, sin embargo, las asesorías se realizan mediante videollamada por lo que se atienden personas que estén ubicadas en cualquier lugar del país",
	},
	{
		id: 6,
		title: "¿Cuál es el horario de atención? ",
		description:
			"El horario de atención para asesorías jurídicas es de 8:00 am a 7:00 pm de lunes a viernes",
	},
];

export default function useFaqs() {
	return { faqs, loading: false };
}
