import {
	getFirestore,
	collection,
	getDocs,
	query,
	where,
	deleteDoc,
	doc,
	setDoc,
	updateDoc,
	getDoc,
} from "firebase/firestore";
import {
	getStorage,
	ref,
	uploadBytes,
	getDownloadURL,
	deleteObject,
} from "firebase/storage";
import { useContext, useState } from "react";
import { getCurrentDate } from "../../constants/helperts";
import app from "../../firebase/config";
import { SnackContext } from "../persistence/Snacks";
import { IBlog } from "../types/CommonTypes";

export default function useFirebase() {
	const [loading, setLoading] = useState<boolean>(false);
	const { setSnack } = useContext(SnackContext);
	const db = getFirestore(app);

	const createTransaction = (path: string) => {
		const db = getFirestore(app);
		const transaction = doc(collection(db, path));
		return transaction.id;
	};

	const createDocument = async (blog: IBlog, callback: any) => {
		try {
			setLoading(true);
			const db = getFirestore(app);
			const newblogRef = doc(collection(db, "blogs"));
			let date = getCurrentDate();
			uploadPicture(
				`/assets/blogs/${date}`,
				blog.picture,
				async function (succes: boolean, url: string) {
					if (succes) {
						await setDoc(newblogRef, {
							id: newblogRef.id,
							title: blog.title,
							description: blog.description,
							picture: {
								name: `${date}`,
								path: `/assets/blogs/${date}`,
								url: url,
							},
							date: getCurrentDate(),
						});
						callback(true);
					} else {
						callback(false);
					}
					setLoading(false);
				}
			);
		} catch (error) {
			console.log("No se pudo crear el documento", error);
			callback(false);
		}
	};

	const updateDocument = async (
		data: any,
		path: string,
		callback: any,
		idDoc?: string
	) => {
		try {
			console.log("DB =>", db);
			setLoading(true);
			if (data.picture.ref) {
				deletePicture(
					data.picture.path,
					data.picture.name,
					function (success: boolean) {
						let date = getCurrentDate();
						if (success) {
							uploadPicture(
								`/assets/blogs/${date}`,
								data.picture.ref,
								async function (
									success: boolean,
									link: string
								) {
									if (success) {
										const newblogRef = doc(
											collection(db, path),
											idDoc
										);
										await updateDoc(newblogRef, {
											id: newblogRef.id,
											title: data.title,
											description: data.description,
											picture: {
												name: `${date}`,
												path: `/assets/blogs/${date}`,
												url: link,
											},
											date: getCurrentDate(),
										});
										setSnack({
											msg: "Tu blog se ha actualizado!",
										});
										setLoading(false);
										localStorage.clear();
										callback(true);
									} else {
										setSnack({
											msg: "Ha ocurrido un error",
											error: true,
										});
									}
								}
							);
						} else {
							setSnack({
								msg: "Ha ocurrido un error",
								error: true,
							});
						}
					}
				);
			} else {
				const docRef = doc(db, "blogs", idDoc?.trim() || "");
				await updateDoc(docRef, {
					id: docRef.id,
					title: data.title,
					description: data.description,
					picture: data.picture,
					date: getCurrentDate(),
				});
				setSnack({
					msg: "Tu blog se ha actualizado!",
				});
				setLoading(false);
				localStorage.clear();
				callback(true);
			}
		} catch (error) {
			console.log("No se pudo crear el documento", error);
			setSnack({
				msg: "Ha ocurrido un error",
				error: true,
			});
			setLoading(false);
			callback(false);
		}
	};

	const uploadPicture = async (path: string, picture: any, callback: any) => {
		try {
			const storage = getStorage();
			const storageRef = ref(storage, path);
			uploadBytes(storageRef, picture).then((snapshot) => {
				getDownloadURL(storageRef)
					.then((link) => callback(true, link))
					.catch((error) => {
						console.log("No se obtener el link de descarga", error);
						callback(false, "");
					});
			});
		} catch (error) {
			console.log("Error subiendo la imagen", error);
			callback(false);
		}
	};

	const deletePicture = async (path: string, name: string, callback: any) => {
		const storage = getStorage();
		const desertRef = ref(storage, `assets/blogs/${name}`);
		deleteObject(desertRef)
			.then(() => {
				callback(true);
			})
			.catch((error) => {
				console.log("No se pudo eliminar la imagen", error);
				callback(false);
			});
	};

	const getData = async (path: string, callback: any) => {
		try {
			setLoading(true);
			const db = getFirestore(app);
			const ref = collection(db, path);
			const snap = await getDocs(ref);
			const snapList = snap.docs.map((doc) => doc.data());
			setLoading(false);
			callback(snapList);
		} catch (error) {
			console.log("Error trayendo servicios", error);
			setLoading(false);

			callback(undefined);
		}
	};

	const getDocument = async (
		path: string,
		paramName: string,
		paramValue: string,
		callback: any
	) => {
		try {
			setLoading(true);
			const db = getFirestore(app);
			const q = query(
				collection(db, path),
				where(paramName, "==", paramValue)
			);
			const querySnapshot = await getDocs(q);
			let blogs: any[] = [];
			querySnapshot.docs.map((doc) => blogs.push(doc.data()));
			setLoading(false);
			callback(blogs);
		} catch (error) {
			console.log("Error trayendo blog por id", error);
			setLoading(false);
		}
	};

	const deleteDocumentById = async (
		path: string,
		id: string,
		callback: any,
		picture?: any
	) => {
		try {
			setLoading(true);
			if (picture) {
				deletePicture(
					picture.path,
					picture.name,
					async function (succes: boolean) {
						const db = getFirestore(app);
						if (succes) {
						} else {
							callback(false);
						}
						let response = await deleteDoc(doc(db, path, id));
						console.log("Response Delete", response);
						callback(true);
						setLoading(false);
					}
				);
			} else {
				const db = getFirestore(app);
				let response = await deleteDoc(doc(db, path, id));
				console.log("Response Delete", response);
				callback(true);
				setLoading(false);
			}
		} catch (error) {
			console.log("Error eliminando documento", error);
			setLoading(false);

			callback(false);
		}
	};

	return {
		getData,
		getDocument,
		deleteDocumentById,
		createDocument,
		uploadPicture,
		createTransaction,
		updateDocument,
		loading,
	};
}
