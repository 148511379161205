import {
	Grid,
	StandardTextFieldProps,
	TextField,
	Typography,
} from "@mui/material";
import { useEffect } from "react";
import GridResponsive from "../grid/GridResponsive";

interface ICustomInput extends StandardTextFieldProps {
	onChange: any;
	label?: string;
	sm?: number;
	md?: number;
	width?: string;
}

export default function CustomInput(props: ICustomInput) {
	const { onChange, label = "", sm = 12, md = 5, width } = props;

	const handleChange = (e: any) => {
		onChange(e.target.value);
	};

	return (
		<GridResponsive item sm={sm} md={md}>
			<div
				style={{
					width: width
						? width
						: window.innerWidth > 750
						? "50%"
						: "90%",
				}}
			>
				<TextField
					{...props}
					fullWidth
					onChange={handleChange}
					required
				/>
			</div>
		</GridResponsive>
	);
}
