import { useContext, useEffect, useState } from "react";

import {
	browserSessionPersistence,
	getAuth,
	setPersistence,
	signInWithEmailAndPassword,
	signOut,
} from "firebase/auth";
import app from "../../firebase/config";
import { UserContext } from "../persistence/UserContext";

export default function useAuth() {
	const [isLogin, setIsLogin] = useState<boolean>(false);

	const handleLogin = (email: string, password: string, callback: any) => {
		setIsLogin(true);
		const auth = getAuth(app);

		signInWithEmailAndPassword(auth, email, password)
			.then((res) => {
				setPersistence(auth, browserSessionPersistence)
					.then(() => {
						return signInWithEmailAndPassword(
							auth,
							email,
							password
						);
					})
					.catch((error) => {
						// Handle Errors here.
						const errorCode = error.code;
						const errorMessage = error.message;
					});
				callback(true, "");
			})
			.catch((error) => {
				console.log("No se ha podido crear el usuario", error.code);
				callback(false, error.code);
			})
			.finally(() => setIsLogin(false));
	};

	const handleLogOut = (callback: any) => {
		setIsLogin(true);
		const auth = getAuth(app);
		signOut(auth)
			.then((res) => {
				console.log("Sesion cerrada");

				callback(true);
			})
			.catch((error) => {
				console.log("No se ha podido cerrar la sesion", error.code);
				callback(false, error.code);
			})
			.finally(() => setIsLogin(false));
	};

	return {
		isLogin,
		handleLogin,
		handleLogOut,
	};
}
