import { useLocation } from "react-router-dom";
import CustomButton from "../../../commons/components/forms/CustomButton";
import Footer from "../../../components/footer/Footer";
import useServices from "../../../commons/hooks/useServices";
import "../../../styles/Detail.css";

export default function ServiceDetail() {
	const location = useLocation();
	const { category, title } = location.state || {};
	const { getServiceByTitle } = useServices();

	const openWhatsapp = () => {
		let url = `https://api.whatsapp.com/send/?phone=573123135227&text=Hola, me gustia obtener mas información acerca del servicio '${service.title}' `;
		window.open(url, "_blank");
	};

	const service = getServiceByTitle(category, title)!;

	return (
		<div className="container-detail">
			<div className="sub-container">
				<div className="picture-container">
					<img style={{ width: "100%" }} src={service.picture} />
				</div>
				<div className="content-container">
					<h1 className="title-detail">{service.title}</h1>
					<p className="description-detail">{service.description}</p>
					<CustomButton
						title="Más información"
						onClick={openWhatsapp}
					/>
				</div>
			</div>
			<Footer />
		</div>
	);
}
