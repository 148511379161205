import { Grid, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../commons/components/forms/CustomButton";
import CustomInput from "../../commons/components/forms/CustomInput";
import CustomTitle from "../../commons/components/forms/CustomTitle";
import GridResponsive from "../../commons/components/grid/GridResponsive";
import useAuth from "../../commons/hooks/useAuth";
import { SnackContext } from "../../commons/persistence/Snacks";
import { UserContext } from "../../commons/persistence/UserContext";
import { IAuthUser } from "../../commons/types/CommonTypes";
import colors from "../../constants/colors";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginSchema } from "../../constants/helperts";

export default function Login() {
	const [error, setError] = useState<string>("");
	const { isLogin, handleLogin } = useAuth();
	const { user } = useContext(UserContext);
	const { setSnack } = useContext(SnackContext);
	const navigate = useNavigate();

	const {
		handleSubmit,
		register,
		reset,
		formState: { errors, isSubmitting },
	} = useForm({
		defaultValues: {
			email: "",
			password: "",
		},
		mode: "onBlur",
		resolver: yupResolver(loginSchema),
	});

	useEffect(() => {
		if (user) {
			navigate("/dashboard");
		}
	}, [user]);

	const onSubmit = (values: any) => {
		if (values.email.trim() !== "" && values.password.trim() !== "")
			handleLogin(
				values.email,
				values.password,
				function (success: boolean, msg: string) {
					if (success) {
						setSnack({ msg: "Acceso concedido" });
						navigate("/dashboard");
					} else {
						setSnack({ msg: "Acceso denegado", error: true });
						switch (msg) {
							case "auth/invalid-email":
								setError("Correo inválido");
								break;
							case "auth/user-not-found":
								setError("El usuario no está registrado");
								break;
							case "auth/wrong-password":
								setError("Datos incorrectos");
								break;
							case "auth/too-many-requests":
								setError(
									"Usuario bloqueado, intente más tarde"
								);
								break;
							default:
								setError(msg);
								break;
						}
					}
				}
			);
	};

	return (
		<div className="main-container">
			<div className="form-container" style={{ height: "400px" }}>
				<GridResponsive>
					<CustomTitle>Iniciar Sesión</CustomTitle>
				</GridResponsive>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="inner-form">
						<label htmlFor="email">Correo electrónico</label>
						<input
							className="custom-input"
							id="email"
							placeholder="Correo electrónico"
							type="email"
							{...register("email")}
						/>
						{errors.email && (
							<span className="error-message">
								{errors.email.message}
							</span>
						)}
						<label htmlFor="password">Contraseña</label>
						<input
							className="custom-input"
							id="password"
							placeholder="Contraseña"
							type="password"
							{...register("password")}
						/>
						{errors.password && (
							<span className="error-message">
								{errors.password.message}
							</span>
						)}
						<div style={{ marginTop: "50px" }}></div>
						<CustomButton
							type="submit"
							width="100%"
							isloading={isLogin}
							title="Ingresar"
							onClick={() => {}}
						/>
					</div>
				</form>
			</div>
		</div>
	);
}
