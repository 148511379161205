export interface IBlog {
	id?: string;
	title: string;
	description: string;
	date?: string;
	picture: IPicture | any;
}

export interface IService {
	id?: string;
	title: string;
	description: string;
	picture: IPicture;
}

export interface IPicture {
	name: string;
	path: string;
	url: string;
	ref: any;
}

export interface ITransactionStatus {
	succes: boolean;
	title: string;
	description: string;
}

export enum StatusPayment {
	PENDING = "PENDING",
	APPROVED = "APPROVED",
	DECLINED = "DECLINED",
	VOIDED = "VOIDED",
	ERROR = "ERROR",
}

export enum PaymentPlatform {
	MERCADO_P = "MERCADO_P",
	WOMPI = "WOMPI",
}

export interface IWompiTransaction {
	id: string;
	created_at: string;
	amount_in_cents: number;
	status: StatusPayment | string;
	reference: string;
	customer_email: string;
	currency: string;
	payment_method_type: string;
	payment_method: {
		type: string;
		phone_number: number;
	};
	shipping_address: {
		address_line_1: string;
		country: string;
		region: string;
		city: string;
		phone_number: number;
	};
	redirect_url: string;
	payment_link_id: string | null;
	origin?: string;
}

export interface IMPTransaction {
	id: number;
	date_created: string;
	date_approved: string;
	date_last_updated: string;
	money_release_date: string;
	payment_method_id: string;
	payment_type_id: string;
	status: string;
	status_detail: string;
	currency_id: string;
	description: string;
	collector_id: number;
	payer: {
		id: number;
		email: string;
		identification: {
			type: string;
			number: number;
		};
		type: string;
	};
	transaction_amount: number;
	transaction_amount_refunded: number;
	coupon_amount: number;
	transaction_details: {
		net_received_amount: number;
		total_paid_amount: number;
		overpaid_amount: number;
		installment_amount: number;
	};
	installments: number;
	origin: string;
}

export interface IPaymentData {
	name: string;
	email: string;
	phoneNumber: number | string;
	docId: string;
	ammount: number;
}

export const SuccessTransaction = {
	succes: true,
	title: "¡Pago exitoso!",
	description: "Tu pago ha sido aprobado",
};

export const DeclinedTransaction = {
	succes: false,
	title: "¡Pago rechazado!",
	description: "Lo sentimos, tu pago ha sido rechazado",
};

export const VoidedTransaction = {
	succes: false,
	title: "¡Transacción anulada!",
	description: "Tu pago ha sido anulado",
};

export const ImgSucces =
	"https://firebasestorage.googleapis.com/v0/b/pdtrans-8631a.appspot.com/o/assets%2Fpayments%2FSuccessful%20purchase-cuate.png?alt=media&token=74aaeb48-19c7-489e-98d8-a3e28a34e5a0";
export const ImgError =
	"https://firebasestorage.googleapis.com/v0/b/pdtrans-8631a.appspot.com/o/assets%2Fpayments%2FError%20purchase.png?alt=media&token=d8f4420a-8541-4bcc-a91f-e8a39c53c4e8";

export const ErrorTransaction = {
	succes: false,
	title: "Error interno",
	description: "Disculpanos, no podemos procesar el pago en estos momentos",
};

export const OnlyDesktop = {
	xs: "none",
	sm: "block",
	md: "block",
	lg: "block",
};

export const OnlyMobile = {
	xs: "block",
	sm: "block",
	md: "none",
	lg: "none",
};

export interface IAuthUser {
	email: string;
	password: string;
}

export function htmlDecode(content: string) {
	let e = document.createElement("div");
	e.innerHTML = content;
	return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
}
