import useViewPort from "../../../commons/hooks/useViewPort";
import useServices from "../../../commons/hooks/useServices";
import Loading from "../../../commons/components/loading/Loading";
import "../../../styles/Services.css";
import CustomTitle from "../../../commons/components/forms/CustomTitle";
import { useEffect, useState } from "react";
import colors from "../../../constants/colors";
import CustomCarousel from "../../../commons/components/carousel/CustomCarousel";
import CustomCard from "../../../commons/components/cards/CustomCard";
import { Enterprise, Person, School } from "../../../assets";

export default function ServicesList() {
	const { ref, playAnimation } = useViewPort();
	const { services, loading, category: active, setCategory } = useServices();

	const tabs = [
		{
			name: "Instituciones Educativas",
			onclick: () => setCategory("Instituciones Educativas"),
			icon: School,
		},
		{
			name: "Empresas",
			onclick: () => setCategory("Empresas"),
			icon: Enterprise,
		},
		{
			name: "Personas Naturales",
			onclick: () => setCategory("Personas Naturales"),
			icon: Person,
		},
	];

	return (
		<div className="service-container" id="servicios">
			<CustomTitle margin="0px">NUESTROS SERVICIOS</CustomTitle>
			<div style={{ marginBottom: "70px" }}></div>
			{loading ? (
				<Loading />
			) : (
				<div className="service-subContainer">
					<div className="tabs">
						{tabs.map((tab, index) => (
							<div
								key={index}
								className="tab"
								onClick={tab.onclick}
								style={
									tab.name === active
										? { opacity: 1, color: colors.LIGHT }
										: { opacity: 0.5, color: colors.TEXT }
								}
							>
								<div className="tab-name">
									<img className="icon" src={tab.icon} />
									<span>{tab.name}</span>
								</div>
							</div>
						))}
					</div>
					<div className="content">
						<CustomCarousel animation="slide" category={active}>
							{services.map((item, index) => (
								<CustomCard
									key={index}
									title={item.title}
									description={item.description}
									image={item.picture}
									category={active}
								/>
							))}
						</CustomCarousel>
					</div>
				</div>
			)}
		</div>
	);
}
