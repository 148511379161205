import { createContext } from "react";

export interface ISnack {
	msg: string;
	error?: boolean;
}
export const SnackContext = createContext({
	snack: { msg: "" },
	setSnack: (snack: ISnack) => {},
});
