import { Grid } from "@mui/material";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import SEO from "react-seo-component";
import Loading from "../../commons/components/loading/Loading";
import useBlogServices from "../../commons/hooks/useBlogServices";
import "../../styles/BlogPreview.css";
import { formatearFecha } from "../../constants/helperts";
import Footer from "../../components/footer/Footer";

export default function BlogViewer() {
	const { title, id } = useParams();
	const location = useLocation();
	const blog = JSON.parse(location.state);

	if (blog) {
		return (
			<Grid style={{ paddingBottom: "100px" }} container>
				<SEO
					title={title || "Derechos personas trans"}
					titleTemplate={blog.title}
					titleSeparator={`-`}
					description={blog.description}
					image={blog.picture.url}
					pathname={blog.title}
					siteLanguage={"es"}
					siteLocale={"co"}
					author={"Zasha medina"}
					article={true}
					twitterUsername={""}
				/>

				<div className="blog-preview">
					<div className="picture-container-preview">
						<img
							src={blog.picture.url}
							alt={blog.title}
							className="picture-preview"
						/>
					</div>
					<div className="content-preview">
						<h1 className="title-preview">{blog.title}</h1>
						<p>{formatearFecha(blog.date!)}</p>
						<p
							className="description-preview"
							dangerouslySetInnerHTML={{
								__html: blog.description,
							}}
						/>
					</div>
				</div>
				<Footer />
			</Grid>
		);
	} else {
		return <Loading />;
	}
}
