import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HelmetProvider, Helmet } from "react-helmet-async";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(
	<React.StrictMode>
		<HelmetProvider>
			<div>
				<Helmet>
					<title>Derechos personas trans</title>
					<meta
						name="description"
						content="En Derechos personas trans te ofrecemos orientación jurídica de acuerdo a tus necesidades para que puedas ejercer tus derechos de forma plena y sin discriminación"
					/>
				</Helmet>
			</div>
			<App />
		</HelmetProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
