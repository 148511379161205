import {
	Toolbar,
	Divider,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from "@mui/material";
import CustomListItem from "./CustomListItem";
import "../../../styles/CustomTable.css";

interface IProps {
	databaseMenu: any[];
	drawerWidth: number;
	handleDrawerToggle: any;
	setCurrentOption: any;
	currentOption: string;
	menu: any[];
	options: any[];
}

const DrawerChild = (props: IProps) => {
	const {
		databaseMenu,
		drawerWidth,
		handleDrawerToggle,
		setCurrentOption,
		currentOption,
		menu,
		options,
	} = props;
	return (
		<div>
			<Toolbar />
			<Divider />
			{/* MENU */}
			<List>
				<List className={"listItems"}>
					{menu.map((item, index) => (
						<CustomListItem
							key={index}
							item={item}
							setCurrentOption={setCurrentOption}
							currentOption={currentOption}
							icon={item.icon}
						/>
					))}
				</List>
			</List>
			<Divider />

			{/* TABLAS */}
			<List className={"listItems"}>
				{databaseMenu.map((item, index) => (
					<CustomListItem
						key={index}
						item={item}
						setCurrentOption={setCurrentOption}
						currentOption={currentOption}
						icon={item.icon}
					/>
				))}
			</List>
			<Divider />

			{/* OPCIONES */}
			<List className={"listItems"}>
				{options.map((item, index) => (
					<ListItem key={index} disablePadding onClick={item.action}>
						<ListItemButton color="secondary">
							<ListItemIcon color="secondary">
								{item.icon}
							</ListItemIcon>
							<ListItemText primary={item.label} />
						</ListItemButton>
					</ListItem>
				))}
			</List>
			<Divider />
		</div>
	);
};

export default DrawerChild;
