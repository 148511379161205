const PRIMARY = "#286075";
const SECONDARY = "#ffa5b8";
const LIGHT = "#ffffff";
const TEXT = "#000";
const ERROR = "#B00020";
export default {
	PRIMARY,
	SECONDARY,
	LIGHT,
	TEXT,
	ERROR,
};
