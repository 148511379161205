import { Typography } from "@mui/material";
import { ReactNode } from "react";
import colors from "../../../constants/colors";

interface ICustomTitle {
	children: ReactNode;
	textAlign?: string;
	margin?: string;
	fontSize?: string;
	color?: string;
}

export default function CustomTitle(props: ICustomTitle) {
	const {
		children,
		textAlign = "center",
		margin = "20px",
		fontSize = "2.7rem",
		color = colors.SECONDARY,
	} = props;
	return (
		<Typography
			variant="h2"
			color={color}
			sx={{
				display: { md: "flex" },
				fontWeight: 900,
				fontSize: fontSize,
				textAlign: { textAlign },
				margin: margin,
				letterSpacing: "1px",
			}}
		>
			{children?.toString().toUpperCase()}
		</Typography>
	);
}
