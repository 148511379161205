import "../../../styles/About.css";
import { Profile } from "../../../assets";

export default function About() {
	return (
		<div id="quienes-somos" className="container-about">
			<div className="left-side-about">
				<div className="picture-container-about">
					<img src={Profile} className="picture-about" />
				</div>
			</div>
			<div className="right-side-about">
				<p className="subtitle">¿Quiénes somos?</p>
				<h1 className="title">
					“Enséñale sobre las diferencias. Haz las diferencias
					ordinarias. Haz las diferencias normales” - Chimamanda Ngozi
					Adichie
				</h1>
				<div className="row-items">
					<p>
						<strong>DEPETRA</strong> nace cómo respuesta a la
						evolución social y normativa de los derechos de las
						personas trans, cómo abogados especializados en derechos
						humanos atendemos las necesidades que nacen en empresas,
						instituciones educativas y personas para la
						implementación efectiva de los mismos. Valoramos la
						singularidad, y las diferencias de cada individuo,
						convencidos de la importancia de crear una sociedad más
						empática e incluyente
					</p>

					<p>
						Nuestro equipo es liderado por Zasha Medina Gómez
						Abogada (2015) especializada en Derechos Humanos y DIH
						de la Universidad Externado de Colombia (2016) y Máster
						en Derecho Internacional de la Universidad Complutense
						de Madrid (2018-2019), cuenta con más de 8 años de
						experiencia en asesoría jurídica en protección de
						derechos humanos y elaboración de proyectos sociales con
						enfoque diferencial
					</p>
				</div>
			</div>
		</div>
	);
}
