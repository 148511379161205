import { Grid, IconButton, Typography } from "@mui/material";
import CustomTitle from "../../commons/components/forms/CustomTitle";
import GridResponsive from "../../commons/components/grid/GridResponsive";
import colors from "../../constants/colors";
import CopyrightIcon from "@mui/icons-material/Copyright";
import icons from "../../constants/icons";

export default function Footer() {
	const currentYear = new Date().getFullYear();

	return (
		<Grid
			container
			style={{
				marginTop: "100px",
				backgroundColor: colors.PRIMARY,
				padding: "20px",
				color: "white",
			}}
		>
			<GridResponsive withoutMargin>
				<IconButton>
					<img
						alt="icon"
						style={{ width: "80px", height: "80px" }}
						src={icons.Icon}
					/>
				</IconButton>
			</GridResponsive>

			<GridResponsive withoutMargin>
				<Typography component="h1" style={{ textAlign: "center" }}>
					<p className="copyright">
						&copy; {currentYear} Derechos Personas Trans <br />{" "}
						Todos los derechos reservados <br /> Prohibida su
						distribución para fines maliciosos
					</p>
				</Typography>
			</GridResponsive>
			<GridResponsive withoutMargin>
				<Typography component="h3" style={{ textAlign: "center" }}>
					Diseñado y desarrollado por{" "}
					<a
						href="https://survivorslabs.com/"
						target="__blank"
						style={{ textDecoration: "none", color: "#ffa5b8" }}
					>
						Survivors Labs
					</a>
				</Typography>
			</GridResponsive>
		</Grid>
	);
}
