import { Icon, IconButton, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomEditor from "../../commons/components/editor/CustomEditor";
import CustomButton from "../../commons/components/forms/CustomButton";
import CustomInput from "../../commons/components/forms/CustomInput";
import CustomSubtitle from "../../commons/components/forms/CustomSubtitle";
import useBlogServices from "../../commons/hooks/useBlogServices";
import { SnackContext } from "../../commons/persistence/Snacks";
import { IBlog } from "../../commons/types/CommonTypes";
import { getCurrentDate } from "../../constants/helperts";

export default function CreateBlog() {
	const { id } = useParams<string>();
	const { setSnack } = useContext(SnackContext);
	const { loading, createBlog, getBlogById, blog, updateBlog } =
		useBlogServices();
	const inputRef = useRef<any>(null);
	const inputRef2 = useRef<any>(null);
	const navigate = useNavigate();
	const [error, setError] = useState<string>("");
	const [isEdittingPicture, showEditPicture] = useState<boolean>(false);
	const [blogDto, setBlog] = useState<IBlog>(
		blog || { title: "", description: "", picture: {} }
	);
	const [picture, setPicture] = useState<any>();

	useEffect(() => {
		if (id) {
			getBlogById(id);
		}
	}, []);

	useEffect(() => {
		blog && setBlog(blog);
	}, [blog]);

	useEffect(() => {
		setError("");
	}, [blogDto]);

	const handlePicture = (e: any) => {
		let file = e.target.files[0];

		if (file.type === "image/png" || file.type === "image/jpeg") {
			setBlog({ ...blogDto, picture: { ...blogDto.picture, url: null } });
			setPicture(file);
			setSnack({ msg: "Archivo cargado" });
		} else {
			setSnack({ msg: "Archivo no válido", error: true });
		}
	};

	const onSubmit = async () => {
		if (
			!blog &&
			(!picture ||
				blogDto.title.trim() === "" ||
				blogDto.description.length === 0)
		) {
			setError("Todos los campos son obligatorios");
		} else {
			blog
				? updateBlog(
						{
							...blogDto,
							picture: picture
								? {
										...blogDto.picture,
										ref: picture,
								  }
								: blogDto.picture,
						},
						(success: boolean) => {
							continueFlow(success);
						}
				  )
				: createBlog(
						{
							...blogDto,
							picture: picture,
							date: getCurrentDate(),
						},
						function (success: boolean) {
							continueFlow(success);
						}
				  );
		}
	};

	const continueFlow = (success: boolean) => {
		if (success) {
			navigate("/dashboard");
		} else {
			setError("Ha ocurrido un error");
		}
	};

	return (
		<Container style={{ marginTop: "100px", paddingBottom: "100px" }}>
			<CustomSubtitle>Título del blog</CustomSubtitle>
			<CustomInput
				style={{
					marginBottom: "30px",
				}}
				md={12}
				width="100%"
				value={blogDto.title}
				onChange={(text: string) =>
					setBlog({ ...blogDto, title: text })
				}
				placeholder="Tu increible blog"
			/>
			<CustomSubtitle>Encabezado</CustomSubtitle>
			{picture || blogDto?.picture.url ? (
				<div style={{ position: "relative" }}>
					<IconButton
						style={{
							position: "absolute",
							top: 10,
							left: 10,
						}}
						onClick={() => {
							inputRef2?.current?.click();
						}}
					>
						<input
							ref={inputRef2}
							type="file"
							onChange={(e) => handlePicture(e)}
							style={{ display: "none" }}
						/>
						<img
							width={"50"}
							height="50"
							src={
								"https://cdn-icons-png.flaticon.com/512/5996/5996831.png"
							}
						/>
					</IconButton>

					<img
						src={
							blogDto.picture.url
								? blogDto.picture.url
								: URL.createObjectURL(picture)
						}
						style={{
							opacity: isEdittingPicture ? 0.2 : 1,
							width: "100%",
							maxHeight: "600px",
						}}
					></img>
				</div>
			) : (
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						width: "100%",
						border: picture ? "none" : "1px solid #000",
						borderStyle: "dashed",
						height: picture ? "600px" : "200px",
						marginBottom: "10px",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<IconButton onClick={() => inputRef?.current?.click()}>
						<input
							ref={inputRef}
							type="file"
							onChange={(e) => handlePicture(e)}
							style={{ display: "none" }}
						/>
						<img
							width={"90"}
							height="90"
							src={
								"https://cdn-icons-png.flaticon.com/512/892/892692.png"
							}
							style={{ display: "block" }}
						/>
					</IconButton>

					<h5>Selecciona una imagen en formato .png o .jpg</h5>
				</div>
			)}

			<CustomEditor
				initialText={blog?.description}
				onChange={(text: string) =>
					setBlog({ ...blogDto, description: text })
				}
			/>
			<Typography
				variant="h5"
				color="red"
				sx={{ fontStyle: "italic" }}
				fontSize={16}
			>
				{error}
			</Typography>
			<CustomButton
				title={blog ? "Actualizar" : "Publicar"}
				onClick={onSubmit}
				isloading={loading}
				disabled={loading}
			/>
		</Container>
	);
}
