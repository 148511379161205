import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import icons from "../../constants/icons";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import useAuth from "../../commons/hooks/useAuth";
import { UserContext } from "../../commons/persistence/UserContext";

const pages = [
	"Inicio",
	"¿Quiénes Somos?",
	"Servicios",
	"Blog",
	"FAQS",
	"Pagos",
];
const hrefs = [
	"/",
	"/#quienes-somos",
	"/#servicios",
	"/#blog",
	"/#faqs",
	"/pagos",
];

export default function AppBarMenu() {
	const navigate = useNavigate();
	const [anchorElNav, setAnchorElNav] = useState<any>(null);
	const [anchorElUser, setAnchorElUser] = useState<any>(null);
	const { user } = useContext(UserContext);
	const { handleLogOut } = useAuth();

	const handleOpenNavMenu = (event: any) => {
		setAnchorElNav(event.currentTarget);
	};
	const handleOpenUserMenu = (event: any) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const handleNavigation = (path: string) => {
		const [basePath, hash] = path.split("#");
		navigate(basePath, { state: { hash } });
	};

	const settingsWithoutLogin = [
		{
			label: "Ingresar",
			action: () => {
				navigate("login");
				setAnchorElUser(null);
			},
		},
	];
	const settingsWithLogin = [
		{
			label: "Panel de control",
			action: () => {
				navigate("/dashboard");
				setAnchorElUser(null);
			},
		},
		{
			label: "Cerrar sesión",
			action: () => {
				handleLogOut(function (success: boolean) {
					if (success) navigate("/");
				});
			},
		},
	];

	return (
		<AppBar
			style={{
				position: "fixed",
				margin: 0,
			}}
		>
			<Container maxWidth="xl">
				<Toolbar disableGutters>
					<IconButton sx={{ display: { xs: "none", md: "flex" } }}>
						<img
							style={{ width: "50px", height: "50px" }}
							src={icons.Icon}
						/>
					</IconButton>
					<Typography
						variant="h6"
						noWrap
						component="a"
						href="/"
						sx={{
							mr: 2,
							display: { xs: "none", md: "flex" },

							fontWeight: 700,
							letterSpacing: ".3rem",
							color: "inherit",
							textDecoration: "none",
						}}
					>
						DEPETRA
					</Typography>

					<Box
						sx={{
							flexGrow: 1,
							display: { xs: "flex", md: "none" },
						}}
					>
						<IconButton
							size="large"
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleOpenNavMenu}
							color="inherit"
						>
							<MenuIcon />
						</IconButton>
						<Menu
							id="menu-appbar"
							anchorEl={anchorElNav}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "left",
							}}
							keepMounted
							transformOrigin={{
								vertical: "top",
								horizontal: "left",
							}}
							open={Boolean(anchorElNav)}
							onClose={handleCloseNavMenu}
							sx={{
								display: { xs: "block", md: "none" },
							}}
						>
							{pages.map((page, index) => (
								<MenuItem
									key={page}
									onClick={() =>
										handleNavigation(hrefs[index])
									}
								>
									<Typography textAlign="center">
										{page}
									</Typography>
								</MenuItem>
							))}
						</Menu>
					</Box>
					<IconButton sx={{ display: { xs: "flex", md: "none" } }}>
						<img
							style={{ width: "30px", height: "30px" }}
							src={icons.Icon}
						/>
					</IconButton>
					<Typography
						variant="h6"
						noWrap
						component="a"
						href="/"
						sx={{
							mr: 2,
							display: { xs: "flex", md: "none" },
							flexGrow: 1,

							fontWeight: 700,
							letterSpacing: ".3rem",
							color: "inherit",
							textDecoration: "none",
						}}
					>
						DEPETRA
					</Typography>
					<Box
						sx={{
							flexGrow: 1,
							display: {
								xs: "none",
								md: "flex",
								justifyContent: "flex-end",
							},
						}}
					>
						{pages.map((page, index) => (
							<Button
								key={page}
								onClick={() => handleNavigation(hrefs[index])}
								sx={{ my: 2, color: "white", display: "block" }}
							>
								{page}
							</Button>
						))}
					</Box>

					<Box sx={{ flexGrow: 0 }}>
						<IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
							<Avatar
								alt="Remy Sharp"
								src="https://firebasestorage.googleapis.com/v0/b/pdtrans-8631a.appspot.com/o/assets%2Ficons%2Fwoman.png?alt=media&token=83d84920-6701-4851-8cf8-5a5d1ead4aad"
							/>
						</IconButton>

						<Menu
							sx={{ mt: "45px" }}
							id="menu-appbar"
							anchorEl={anchorElUser}
							anchorOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
							keepMounted
							transformOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
							open={Boolean(anchorElUser)}
							onClose={handleCloseUserMenu}
						>
							{(user
								? settingsWithLogin
								: settingsWithoutLogin
							).map((setting, i) => (
								<MenuItem key={i} onClick={setting.action}>
									<Typography textAlign="center">
										{setting.label}
									</Typography>
								</MenuItem>
							))}
						</Menu>
					</Box>
				</Toolbar>
			</Container>
		</AppBar>
	);
}
