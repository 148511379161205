import * as yup from "yup";
import StorageIcon from "@mui/icons-material/Storage";
import BookIcon from "@mui/icons-material/Book";

export const getCurrentDate = () => {
	const today = new Date();
	const yyyy = today.getFullYear();
	let mm: string | number = today.getMonth() + 1; // Months start at 0!
	let dd: string | number = today.getDate();
	let hh: string | number = today.getHours();
	let min: string | number = today.getMinutes();
	let ss: string | number = today.getSeconds();

	if (dd < 10) dd = "0" + dd;
	if (mm < 10) mm = "0" + mm;

	const formattedToday =
		dd + "-" + mm + "-" + yyyy + "T" + hh + ":" + min + ":" + ss;

	return formattedToday;
};

export const uriToBlob = (uri: string) => {
	return new Promise((resolve, reject) => {
		const xhr = new XMLHttpRequest();
		xhr.onload = function () {
			// return the blob
			resolve(xhr.response);
		};

		xhr.onerror = function () {
			// something went wrong
			reject(new Error("uriToBlob failed"));
		};
		// this helps us get a blob
		xhr.responseType = "blob";
		xhr.open("GET", uri, true);

		xhr.send(null);
	});
};

export const paymentDataSchema = yup.object().shape({
	name: yup.string().required("Campo obligatorio"),
	email: yup.string().email().required("Campo obligatorio"),
	phoneNumber: yup.string().required("Campo obligatorio"),
	docId: yup.string().required("Campo obligatorio"),
	ammount: yup
		.number()
		.typeError("El valor debe ser numérico")
		.min(15000, "El valor mínimo debe ser 15000")
		.required("Campo obligatorio"),
});

export const loginSchema = yup.object().shape({
	email: yup
		.string()
		.email("Ingrese un correo electrónico válido")
		.required("Campo obligatorio"),
	password: yup.string().required("Campo obligatorio"),
});

const menu = [
	{ label: "Nuevo Artículo", icon: <BookIcon color="secondary" /> },
];

const databaseMenu = [
	{ label: "Entradas", icon: <StorageIcon color="secondary" /> },
];

export function formatearFecha(fecha: string) {
	// Dividimos la fecha y la hora, y luego el día, mes y año
	const [fechaParte, horaParte] = fecha.split("T");
	const [dia, mes, año] = fechaParte
		.split("-")
		.map((num) => parseInt(num, 10));

	// Dividimos la hora en horas y minutos
	const [hora, minuto] = horaParte.split(":").map((num) => parseInt(num, 10));

	// Lista de nombres de meses en español
	const meses = [
		"enero",
		"febrero",
		"marzo",
		"abril",
		"mayo",
		"junio",
		"julio",
		"agosto",
		"septiembre",
		"octubre",
		"noviembre",
		"diciembre",
	];

	// Formateamos el periodo del día en AM o PM
	const periodo = hora < 12 ? "am" : "pm";

	// Convertimos la hora a formato 12 horas
	const hora12 = hora % 12 || 12; // Convertimos 0 en 12 para las 12 AM

	// Añadimos un cero inicial si el minuto es menos de 10
	const minutosFormateados = minuto < 10 ? `0${minuto}` : minuto;

	// Construimos la fecha formateada
	return `${dia} de ${
		meses[mes - 1]
	} ${año} ${hora12}:${minutosFormateados} ${periodo}`;
}

export { menu, databaseMenu };
