import { useEffect, useState } from "react";
import { useInView } from "react-hook-inview";

export default function useViewPort() {
	const [playAnimation, setPlayAnimation] = useState<boolean>(false);

	const [ref, isVisible] = useInView({
		threshold: 0.2,
	});

	useEffect(() => {
		if (!playAnimation) setPlayAnimation(isVisible);
	}, [isVisible]);

	return {
		ref,
		playAnimation,
	};
}
