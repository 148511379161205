import { CircularProgress, Container } from "@mui/material";

export default function Loading() {
	return (
		<Container
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				alignContent: "center",
				height: "100vh",
			}}
		>
			<CircularProgress style={{ alignSelf: "center" }} />
		</Container>
	);
}
