import { Grid } from "@mui/material";
import { ReactNode } from "react";
import Carousel from "react-material-ui-carousel";

interface ICustomCarousel {
	children: ReactNode;
	animation?: "fade" | "slide";
	category: string;
}

export default function CustomCarousel(props: ICustomCarousel) {
	const { children, animation = "fade", category } = props;
	return (
		<Carousel
			key={category}
			animation={animation}
			duration={1000}
			interval={5000}
		>
			{children}
		</Carousel>
	);
}
