interface Service {
	title: string;
	description: JSX.Element;
	picture: string;
}

interface Services {
	[key: string]: Service[];
}

const servicesList: Services = {
	"Personas Naturales": [
		{
			title: "Acción de tutela",
			description: (
				<>
					<p>
						<strong>La acción de tutela</strong> es un mecanismo que
						tiene toda persona para acudir ante autoridades
						judiciales para obtener la protección inmediata de un
						derecho que está siendo amenazado o vulnerado. Esta
						vulneración o amenaza proviene de una autoridad pública
						o de ciertos particulares.
					</p>
					<ul>
						<li>
							<strong>Salud:</strong> El derecho a la salud es un
							elemento estructural de la dignidad humana, por lo
							que el Estado debe garantizar su goce efectivo y
							pleno, esto incluye la parte física, mental,
							emocional y social de toda persona. La población
							trans goza con la garantía y protección
							internacional y nacional de este derecho, sin
							embargo en la práctica se encuentra una serie de
							barreras y obstáculos que ponen en amenaza y
							vulneración la salud y vida de esta población.
						</li>
						<li>
							<strong>Trabajo:</strong> Toda persona tiene derecho
							al trabajo y a la elección libre de este, sin
							discriminación alguna. Cualquier restricción,
							exclusión o reducción es violatorio a este derecho
							fundamental y puede llegar a considerarse cómo un
							trato desigual en la esfera laboral.
						</li>
						<li>
							<strong>Educación:</strong> El derecho a la
							educación permito a toda persona adquirir
							conocimiento, alcanzar el desarrollo de una vida
							social plena y constituir al desarrollo económico,
							social y cultural de una sociedad. Toda persona
							tiene derecho a poder realizar expresiones
							manifestando su orientación sexual y su identidad de
							género en el ámbito educativo. Así mismo, también
							tiene derecho a recibir un trato por parte de la
							Institución acorde a su identidad.
						</li>
					</ul>
				</>
			),
			picture:
				"https://firebasestorage.googleapis.com/v0/b/pdtrans-8631a.appspot.com/o/assets%2Fservices%2Ftutela.png?alt=media&token=50a4a270-3d7a-41cf-b258-1f4f7b6163d9",
		},
		{
			title: "Derecho de petición",
			description: (
				<>
					<p>
						El derecho de petición es un derecho fundamental que
						tiene toda persona para realizar solicitudes respetuosas
						antes las autoridades o entidades. En la práctica el
						derecho de petición sirve para pedir el reconocimiento
						de un derecho, para solicitar la intervención de una
						autoridad en un asunto privado, para requerir
						información, para solicitar que se brinde un servicio,
						para requerir documentos, entre otras. Esta figura ayuda
						a garantizar los derechos fundamentales y muchas veces
						sirve como prueba para eventualmente iniciar un proceso
						jurídico.
					</p>
				</>
			),
			picture:
				"https://firebasestorage.googleapis.com/v0/b/pdtrans-8631a.appspot.com/o/assets%2Fservices%2Fpeticion.png?alt=media&token=d73cd90f-2d29-4ad3-ae87-f7ca7b7659c9",
		},
		{
			title: "Definición de servicio militar para personas trans",
			description: (
				<>
					<p>
						El servicio militar en Colombia es obligatorio para los
						hombres mayores de 18 años y tiene como finalidad que
						todos los colombianos defiendan las instituciones.
						Prestar el servicio militar obligatorio es el requisito
						para poder acceder a la libreta militar. Las personas
						trans encuentran en esta figura una serie de obstáculos
						materiales, burocráticos y sociales que generan
						discriminación y en muchos casos presunto abusos de
						autoridad. En el ordenamiento jurídico colombiano existe
						normatividad que protege y garantiza los derechos y
						libertades de las personas trans.
					</p>
				</>
			),
			picture:
				"https://firebasestorage.googleapis.com/v0/b/pdtrans-8631a.appspot.com/o/assets%2Fservices%2Flawyers.png?alt=media&token=870f2bd4-db44-460a-890e-fc503abcbb04",
		},
		{
			title: "Rectificación del componente sexo en el registro civil",
			description: (
				<>
					<p>
						El ordenamiento jurídico colombiano garantiza el derecho
						a la identidad sexual de todas las personas,
						entendiéndolo como un componente autónomo en el que el
						Estado y la sociedad no pueden intervenir sino
						únicamente reconocerlo y respetarlo.
					</p>
				</>
			),
			picture:
				"https://firebasestorage.googleapis.com/v0/b/pdtrans-8631a.appspot.com/o/assets%2Fservices%2FGender%20identity-bro.png?alt=media&token=5dfed879-da19-44cf-b0cf-bcaf09dd8cd5",
		},
		{
			title: "Procesos jurídicos para la atención integral en salud a personas trans",
			description: (
				<>
					<p>
						Las personas trans tienen derecho a una atención
						integral en los servicios de salud, sin embargo en
						reiteradas ocasiones por su identidad sexual sufren
						reiteradas vulneraciones en la atención médica.
					</p>
				</>
			),
			picture:
				"https://firebasestorage.googleapis.com/v0/b/pdtrans-8631a.appspot.com/o/assets%2Fservices%2FDoctor-pana.png?alt=media&token=e8169ea9-4e5f-4f42-9c7a-360e1c270b2f",
		},
	],
	"Instituciones Educativas": [
		{
			title: "Actualización manual de convivencia",
			description: (
				<>
					<p>
						Realizamos la actualización del manual de convivencia
						para que este sea conforme a la normatividad
						internacional y nacional obligatoria en el Estado
						colombiano
					</p>
				</>
			),
			picture:
				"https://firebasestorage.googleapis.com/v0/b/pdtrans-8631a.appspot.com/o/assets%2Fservices%2FReading%20book-pana.png?alt=media&token=0d84fe3d-df5e-4b02-98b1-f075fd8570b3",
		},
		{
			title: "Diseño de protocolos y rutas pertinentes",
			description: (
				<>
					<p>
						Las instituciones educativas privadas están facultadas
						para prestar el servicio educativo, son sujetos activos
						en el desarrollo del derecho fundamental a la educación,
						en los últimos años la normatividad ha establecido una
						serie de obligaciones para las instituciones en el
						manejo del derecho a la diversidad de género de sus
						estudiantes esto incluye la actualización y creación de
						protocolos para los diferentes hechos que se presentan
						al interior de la institución
					</p>
				</>
			),
			picture:
				"https://firebasestorage.googleapis.com/v0/b/pdtrans-8631a.appspot.com/o/assets%2Fservices%2FCompetitive%20intelligence-pana.png?alt=media&token=dbd042f8-c92c-46ae-ba2f-f82ba4222ea6",
		},
		{
			title: "Capacitación de la comunidad educativa",
			description: (
				<>
					<p>
						Las instituciones educativas son responsables legalmente
						por las acciones u omisiones que realice su talento
						humano, el derecho a la diversidad sexual de los
						estudiantes trae retos para toda la comunidad educativa,
						por ejemplo, en el uso correcto de los pronombres, a
						través de los talleres se dan las herramientas para que
						al interior de las instituciones se implementen las
						obligaciones legales de manera correcta
					</p>
				</>
			),
			picture:
				"https://firebasestorage.googleapis.com/v0/b/pdtrans-8631a.appspot.com/o/assets%2Fservices%2FSeminar-pana.png?alt=media&token=b380f33d-e1f5-4cd0-af90-0dc7d5f8369e",
		},
	],
	Empresas: [
		{
			title: "Capacita a tu talento humano",
			description: (
				<p>
					Damos herramientas para que el talento humano haga uso del
					lenguaje inclusivo con sus colegas y también con los
					clientes, eso aumentará el compromiso, la productividad y la
					retención del personal
				</p>
			),
			picture:
				"https://firebasestorage.googleapis.com/v0/b/pdtrans-8631a.appspot.com/o/assets%2Fservices%2FTeam%20goals-pana.png?alt=media&token=82615e75-9103-4df4-a682-17930c30bb25",
		},
		{
			title: "Diseño de protocolos al interior de la empresa",
			description: (
				<p>
					Creamos y actualizamos los protocolos y activación de rutas
					adecuadas para los diferentes hechos que se puedan generar
					al interior de la empresa, esto ayuda a mejorar el ambiente
					laboral y a estar blindados jurídicamente en el caso de una
					posible demanda
				</p>
			),
			picture:
				"https://firebasestorage.googleapis.com/v0/b/pdtrans-8631a.appspot.com/o/assets%2Fservices%2FInstruction%20manual-pana.png?alt=media&token=a3692344-6ae9-4e08-a7e1-b2d43c66f1f4",
		},
		{
			title: "Actualización de formatos dirigidos al cliente",
			description: (
				<p>
					Actualizamos los formatos que las empresas tengan diseñados
					y dirigidos a sus clientes, muchas veces clientes con
					identidades y orientaciones diversas se sienten excluidos
					porque no son tenidos en cuenta en la documentación
					existente
				</p>
			),
			picture:
				"https://firebasestorage.googleapis.com/v0/b/pdtrans-8631a.appspot.com/o/assets%2Fservices%2FHonesty-pana.png?alt=media&token=aa987662-27ce-4dc5-81bf-2a0a94bafdd2",
		},
	],
};

export default servicesList;
