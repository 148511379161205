import { ReactNode, useContext } from "react";
import { Navigate } from "react-router-dom";
import useAuth from "../../commons/hooks/useAuth";
import { UserContext } from "../../commons/persistence/UserContext";

interface IPrivateRoute {
	children: ReactNode;
}

export default function PrivateRoute(props: IPrivateRoute): any {
	const { children } = props;
	const { user } = useContext(UserContext);

	if (!user) {
		return <Navigate to="/" />;
	}

	return children;
}
