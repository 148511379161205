import { Grid } from "@mui/material";
import { Animate } from "react-simple-animate";
import CustomTitle from "../../../commons/components/forms/CustomTitle";
import GridResponsive from "../../../commons/components/grid/GridResponsive";
import useViewPort from "../../../commons/hooks/useViewPort";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useFaqs from "../hooks/useFaqs";

export default function Faqs() {
	const { ref, playAnimation } = useViewPort();
	const { faqs } = useFaqs();

	return (
		<section
			ref={ref}
			id="faqs"
			style={{ marginTop: "20px", marginBottom: "20px" }}
		>
			<Animate
				play={playAnimation}
				start={{ opacity: 0 }}
				end={{ opacity: 1 }}
				duration={1}
			>
				<Grid container>
					<GridResponsive>
						<CustomTitle>Preguntas Frecuentes</CustomTitle>
					</GridResponsive>
					<Acordion faqs={faqs} />
					<GridResponsive xs={12} md={6}>
						<img
							width={"70%"}
							style={{ maxHeight: "500px" }}
							src="https://firebasestorage.googleapis.com/v0/b/pdtrans-8631a.appspot.com/o/assets%2FQuestions-pana.png?alt=media&token=8bd90365-81d2-42b0-ac1d-6e477c99fad4"
						/>
					</GridResponsive>
				</Grid>
			</Animate>
		</section>
	);
}

function Acordion({ faqs }: any) {
	return (
		<GridResponsive md={6} xs={12}>
			<div
				style={{
					width: "90%",
					alignSelf: "center",
				}}
			>
				{faqs.map((faq: any, key: number) => (
					<Accordion key={key}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							<Typography>{faq.title}</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<p
								style={{ textAlign: "left" }}
								dangerouslySetInnerHTML={{
									__html: faq.description,
								}}
							/>
						</AccordionDetails>
					</Accordion>
				))}
			</div>
		</GridResponsive>
	);
}
