import React, { useEffect } from "react";
import ScrollReveal from "scrollreveal";
import FloatingWhatsApp from "react-floating-whatsapp";
import SEO from "react-seo-component";
import Banner from "../../commons/components/Banner";
import Footer from "../../components/footer/Footer";
import { About, BlogList, Contact, Faqs, ServicesList } from "./components";
import { useLocation } from "react-router-dom";

export default function Home() {
	const location = useLocation();
	const hash = location.state?.hash;

	useEffect(() => {
		if (hash) {
			const element = document.getElementById(hash);
			if (element) {
				element.scrollIntoView({ behavior: "smooth" });
			}
		}
	}, [hash]);

	useEffect(() => {
		const sr = ScrollReveal({
			origin: "bottom",
			distance: "50px",
			duration: 500,
			delay: 400,
			reset: false,
			easing: "ease-in-out",
		});

		sr.reveal(`
            .banner,
            .container-about,
            .service-container,
            .blog-list,
            .faqs,

            .footer
        `);
	}, []);

	return (
		<div>
			<SEO
				title={"Derechos personas trans"}
				titleTemplate={""}
				titleSeparator={` `}
				description={"Conoce tus derechos como persona trans"}
				image={""}
				pathname={"derechospersonastrans"}
				siteLanguage={"es"}
				siteLocale={"co"}
				twitterUsername={""}
			/>
			<Banner />
			<ServicesList />
			<About />
			<BlogList sliced={2} />
			<Faqs />
			{/* <Contact /> */}
			<FloatingWhatsApp
				phoneNumber={"573123135227"}
				accountName={"Zasha Medina"}
				statusMessage="En linea"
				chatMessage="Hola, me encanta tenerte aqui ¿Cómo te puedo ayudar?"
				avatar="https://cdn-icons-png.flaticon.com/512/706/706816.png"
				allowEsc
				notification
				notificationDelay={50000}
				placeholder="Deja tu mensaje..."
			/>
			<Footer />
		</div>
	);
}
