import { Alert, Snackbar, ThemeProvider } from "@mui/material";
import { useEffect, useState } from "react";
import colors from "./constants/colors";
import Router from "./components/navigation/Router";
import theme from "./constants/theme/theme";
import { UserContext } from "./commons/persistence/UserContext";
import { getAuth, User } from "firebase/auth";
import app from "./firebase/config";
import "./App.css";
import { ISnack, SnackContext } from "./commons/persistence/Snacks";
import SnackBar from "./commons/components/alerts/SnackBar";

function App() {
	const [user, setUser] = useState<User>();
	const [snack, setSnack] = useState<ISnack>({ msg: "", error: false });
	const [open, setOpen] = useState<boolean>(false);

	useEffect(() => {
		if (getAuth(app)) {
			const suscriber =
				getAuth(app).onAuthStateChanged(onAuthStateChange);
			return suscriber;
		}
	}, []);

	useEffect(() => {
		if (snack.msg !== "") {
			setOpen(true);
		}
		setTimeout(() => {
			setOpen(false);
		}, 6000);
	}, [snack]);

	const onAuthStateChange = (app: any) => {
		setUser(app?.auth.currentUser);
	};

	return (
		<UserContext.Provider value={{ user, setUser }}>
			<SnackContext.Provider value={{ snack, setSnack }}>
				<div className="App" style={{ backgroundColor: colors.LIGHT }}>
					<ThemeProvider theme={theme}>
						<Router />
						<SnackBar
							open={open}
							msg={snack.msg}
							error={snack.error || false}
						/>
					</ThemeProvider>
				</div>
			</SnackContext.Provider>
		</UserContext.Provider>
	);
}

// https://www.npmjs.com/package/react-seo-component

export default App;
