import { Grid, Typography } from "@mui/material";
import CustomTitle from "../../../commons/components/forms/CustomTitle";
import { useEffect, useState } from "react";
import usePayments from "../hooks/usePayments";
import { Container } from "@mui/system";
import Loading from "../../../commons/components/loading/Loading";
import {
	DeclinedTransaction,
	ErrorTransaction,
	ImgError,
	ImgSucces,
	IWompiTransaction,
	ITransactionStatus,
	PaymentPlatform,
	StatusPayment,
	SuccessTransaction,
	VoidedTransaction,
	IMPTransaction,
} from "../../../commons/types/CommonTypes";
import GridResponsive from "../../../commons/components/grid/GridResponsive";
import CustomButton from "../../../commons/components/forms/CustomButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import useMPServices from "../../mercadopago/hooks/useMPServices";

export default function ResponseCheckout() {
	const {
		loading,
		loadingTransaction,
		getStatusTransaction,
		updateTransaction,
	} = usePayments();
	const { getPaymentById } = useMPServices();
	const navigate = useNavigate();
	const [status, setStatus] = useState<ITransactionStatus>();
	const [searchParams, setSearchParams] = useSearchParams();
	const [feedbackTransaction, setFeedbackTransaction] = useState<string>("");

	useEffect(() => {
		let id_mp = searchParams.get("collection_id");
		let id_wmp = searchParams.get("id");
		let id_pse = searchParams.get("stage");

		if (id_mp) {
			onInitMP(id_mp);
		} else if (id_wmp) {
			onInitWompi();
		} else if (id_pse) {
			console.log("id_pse", id_pse);
			switch (id_pse) {
				case "01":
					setStatus(DeclinedTransaction);
					break;
				case "02":
					setStatus(VoidedTransaction);
					break;
				default:
					setStatus(SuccessTransaction);
					break;
			}
		}
	}, []);

	const onInitMP = (id: string) => {
		getPaymentById(id, function (success: boolean, data: IMPTransaction) {
			if (success) {
				saveTransaction(data, "Mercado Pago", id);
			} else {
				setStatus(ErrorTransaction);
			}
		});
	};

	const onInitWompi = async () => {
		try {
			let id = searchParams.get("id");
			id &&
				(await getStatusTransaction(
					id,
					async function (
						succes: boolean,
						transaction: IWompiTransaction
					) {
						if (succes) {
							saveTransaction(
								transaction,
								"Woompi",
								transaction.reference
							);
						} else {
							setStatus(ErrorTransaction);
						}
					}
				));
		} catch (error) {
			console.log("Error procesando parametros de la transaccion", error);
		}
	};

	const saveTransaction = async (
		transaction: IWompiTransaction | IMPTransaction,
		origin: string,
		idTransaction?: string
	) => {
		transaction.origin = origin;
		await updateTransaction(
			transaction,
			function (success2: boolean) {
				if (!success2) {
					setStatus(ErrorTransaction);
				} else {
					switch (transaction.status) {
						case StatusPayment.DECLINED:
							setStatus(DeclinedTransaction);
							break;
						case StatusPayment.VOIDED:
							setStatus(VoidedTransaction);
							break;
						case StatusPayment.ERROR:
							setStatus(ErrorTransaction);
							break;
						default:
							setStatus(SuccessTransaction);
							break;
					}
				}
				setFeedbackTransaction(idTransaction || "");
			},
			idTransaction
		);
	};

	if (loading || loadingTransaction || !status) {
		return <Loading />;
	} else {
		return (
			<Container>
				<GridResponsive>
					<img
						width={"400px"}
						height="400px"
						src={status.succes ? ImgSucces : ImgError}
					/>
				</GridResponsive>
				<GridResponsive>
					<CustomTitle>{status.title}</CustomTitle>
				</GridResponsive>
				<GridResponsive>
					<Typography variant="body1">
						{status.description}
					</Typography>
				</GridResponsive>
				<GridResponsive>
					{feedbackTransaction !== "" && (
						<Typography variant="body2">
							{`Guarda este ID de transacción para tenerlo de referencia ${feedbackTransaction}`}
						</Typography>
					)}
				</GridResponsive>

				<GridResponsive>
					<CustomButton
						onClick={() => navigate("/")}
						title="Volver al inicio"
					/>
				</GridResponsive>
			</Container>
		);
	}
}
