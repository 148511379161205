// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import env from "react-dotenv";

const firebaseConfig = {
	apiKey: env.apiKey,
	authDomain: env.authDomain,
	projectId: env.projectId,
	storageBucket: env.storageBucket,
	messagingSenderId: env.messagingSenderId,
	appId: env.appId,
	measurementId: env.measurementId,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default app;
