import { useNavigate } from "react-router-dom";
import { IBlog } from "../../types/CommonTypes";
import CustomButton from "../forms/CustomButton";
import GridResponsive from "../grid/GridResponsive";
import "../../../styles/BlogCard.css";

interface IBlogCard {
	blog: IBlog;
	deleteBlog: any;
	editBlog: any;
	isAdmin?: boolean;
	loading: boolean;
}

export default function BlogCard(props: IBlogCard) {
	const { blog, isAdmin = false, deleteBlog, editBlog, loading } = props;
	const navigate = useNavigate();

	const viewFullBlog = () => {
		navigate(`/blog/${blog.title}`, {
			state: JSON.stringify(blog),
		});
	};

	const handleDelete = () => {
		deleteBlog(blog.id || "", blog.picture);
	};

	const handleEdit = () => {
		editBlog(blog.id);
	};

	return (
		<div>
			<div className="card-blog" onClick={viewFullBlog}>
				<div className="image">
					<img className="picture-banner" src={blog.picture.url} />
				</div>
				<div className="content-blog">
					<a href="/blog/${blog.id}">
						<span className="title-blog">{blog.title}</span>
					</a>

					<div className="parent-desc">
						<p
							className="desc"
							dangerouslySetInnerHTML={{
								__html: blog.description,
							}}
						/>
					</div>

					<a className="action" href="#">
						Ver completo
						<span aria-hidden="true">→</span>
					</a>
				</div>
			</div>
			{isAdmin && (
				<GridResponsive flexDirection={"column"}>
					<CustomButton
						title={"Editar"}
						onClick={() => navigate(`/create/${blog.id}`)}
						isloading={loading}
					/>
					<CustomButton
						title={"Eliminar"}
						onClick={handleDelete}
						isloading={loading}
					/>
				</GridResponsive>
			)}
		</div>
	);
}
