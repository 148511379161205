import BlogCard from "../../../commons/components/cards/BlogCard";
import CustomTitle from "../../../commons/components/forms/CustomTitle";
import GridResponsive from "../../../commons/components/grid/GridResponsive";
import useBlogServices from "../../../commons/hooks/useBlogServices";
import useViewPort from "../../../commons/hooks/useViewPort";

interface IBlogList {
	isAdmin?: boolean;
	sliced?: number;
}

export default function BlogList(props: IBlogList) {
	const { ref, playAnimation } = useViewPort();
	const { blogList, loading, deleteBlog, editBlog } = useBlogServices();
	const { isAdmin = false, sliced = 2 } = props;

	return (
		<section
			ref={ref}
			id="blog"
			style={{ marginTop: "20px", marginBottom: "20px" }}
			className="blog-list"
		>
			<div className="left-side">
				<CustomTitle fontSize="4rem">BLOG</CustomTitle>
				<img
					className="picture-list"
					src="https://firebasestorage.googleapis.com/v0/b/pdtrans-8631a.appspot.com/o/assets%2FBlogging-pana%20(1).png?alt=media&token=3be15251-2599-453f-bd94-08dfe72701cb"
				/>
			</div>
			<div className="container-row">
				{blogList
					.slice(0, sliced === 0 ? blogList.length : sliced)
					.map((item, i) => (
						<GridResponsive
							key={i}
							style={{
								padding: "10px",
								margin: "10px",
							}}
							xs={12}
							md={6}
						>
							<BlogCard
								isAdmin={isAdmin}
								blog={item}
								deleteBlog={deleteBlog}
								loading={loading}
								editBlog={editBlog}
							/>
						</GridResponsive>
					))}
			</div>
		</section>
	);
}
