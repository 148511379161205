import { IPaymentData } from "../../../commons/types/CommonTypes";
import env from "react-dotenv";

export default function useMPServices() {
	const URL = "https://api.mercadopago.com";

	const createPreference = async (
		paymentData: IPaymentData,
		callback: any
	) => {
		let data = {
			items: [
				{
					title: "Zasha Medina",
					description: "Pago a Zasha Medina",
					quantity: 1,
					unit_price: parseInt(paymentData.ammount.toString()),
				},
			],
			payer: {
				name: paymentData.name,
				email: paymentData.email,
				phone: { area_code: "57", number: paymentData.phoneNumber },
				identification: { type: "CC", number: paymentData.docId },
			},
			payment_methods: {
				excluded_payment_methods: [{ id: "efecty" }],
			},
			back_urls: {
				success: "https://derechospersonastrans.com/checkout?stage=00",
				failure: "https://derechospersonastrans.com/checkout?stage=01",
				pending: "https://derechospersonastrans.com/checkout?stage=02",
			},
			auto_return: "all",
		};

		fetch(URL + "/checkout/preferences", {
			method: "POST",
			headers: {
				Authorization: `Bearer ${env.MP_TOKEN}`,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		})
			.then((res) => res.json())
			.then((data) => {
				callback(true, data);
			})
			.catch((error) => {
				console.error("No se pudo crear la preferencia");
				callback(false, null);
			});
	};

	const getPaymentById = (id: string, callback: any) => {
		fetch(URL + "/v1/payments/" + id, {
			method: "GET",
			headers: {
				Authorization:
					"Bearer TEST-2772231277827743-102416-dc3277d0850b0fd69a0edc2f4fde9f83-241173507",
				"Content-Type": "application/json",
			},
		})
			.then((res) => res.json())
			.then((data) => {
				callback(true, data);
			})
			.catch((error) => {
				console.error("No se pudo crear la preferencia");
				callback(false, null);
			});
	};

	return { createPreference, getPaymentById };
}
