import { Typography } from "@mui/material";

interface ICustomSubtitle {
	children: any;
}

export default function CustomSubtitle(props: ICustomSubtitle) {
	const { children } = props;
	return (
		<Typography variant="subtitle1" fontSize={"1.5rem"} textAlign="left">
			{children}
		</Typography>
	);
}
