import { AnyARecord } from "dns";
import { useState } from "react";
import useFirebase from "../../../commons/hooks/useFirebase";
import {
	IMPTransaction,
	IWompiTransaction,
} from "../../../commons/types/CommonTypes";

export default function usePayments() {
	const [loadingTransaction, setLoading] = useState<boolean>(false);
	const { createTransaction, updateDocument, loading } = useFirebase();
	const test = true;

	const getStatusTransaction = (id: string, callback: any) => {
		setLoading(true);
		fetch(
			`https://${
				test ? "sandbox" : "production"
			}.wompi.co/v1/transactions/${id}`,
			{
				method: "GET",
			}
		)
			.then((res) => {
				if (res.status === 200) {
					return res.json();
				} else {
					setLoading(false);
					callback(false, "");
					return null;
				}
			})
			.then((data) => {
				setLoading(false);
				callback(true, data.data);
			})
			.catch((error) => {
				setLoading(false);
				console.log(error);
				callback(false, "");
			});
	};

	const updateTransaction = async (
		transaction: IWompiTransaction | IMPTransaction,
		callback: any,
		idTransaction?: string
	) => {
		let paymentData = JSON.parse(
			localStorage.getItem("paymentData") || "{}"
		);
		await updateDocument(
			{ ...transaction, ...paymentData },
			"transactions",
			callback,
			idTransaction
		);
	};

	const createTransactionNumber = (path: string) => {
		return createTransaction(path);
	};

	return {
		getStatusTransaction,
		updateTransaction,
		createTransactionNumber,
		loadingTransaction,
		loading,
	};
}
