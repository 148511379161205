import { Box, Checkbox, FormControlLabel, Grid } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IBlog } from "../../../commons/types/CommonTypes";
import {
	Column,
	CustomTable,
	IAction,
} from "../../../commons/components/table/CustomTable";
import CustomTitle from "../../../commons/components/forms/CustomTitle";
import useBlogServices from "../../../commons/hooks/useBlogServices";

interface IProps {
	onEdit: (blog: IBlog) => void;
	onReset: (location: string) => void;
	newItemRef: string;
}

export default function TableBlogs({ onEdit, newItemRef, onReset }: IProps) {
	const navigate = useNavigate();
	const [limit, setLimit] = useState<number>(6);
	const [prevPage, setPrevPage] = useState<number>(0);
	const { blogList: blogs, loading, deleteBlog } = useBlogServices();

	const onPageChange = (number: number) => {
		if (number > 0 && number > prevPage && blogs.length > prevPage) {
			setPrevPage(prevPage + 1);
			if (number > prevPage) setLimit(limit + 5);
		}
	};

	const blogsWithActions = useCallback(() => {
		return blogs.map((item: IBlog) => ({
			...item,
		}));
	}, [blogs]);

	const columns: Column[] = [
		{ field: "date", headerName: "Fecha creación", flex: 1 },
		{ field: "title", headerName: "Título", flex: 3 },
		{ field: "action", headerName: "Acciones", flex: 1 },
	];

	const actions: IAction[] = [
		{
			label: "Ver",
			action: (blog) => navigate(`/blog/${blog.id}`),
		},
		{
			label: "Eliminar",
			action: (blog) => deleteBlog(blog),
		},
	];

	return (
		<Grid container>
			<CustomTitle color="primary">Entradas</CustomTitle>
			<CustomTable
				buttonRefClick={() => onReset(newItemRef)}
				buttonRef={newItemRef}
				columns={columns}
				rows={blogsWithActions()}
				headerName="Blogs"
				actions={actions}
				loading={loading}
				onPageChange={onPageChange}
			/>
		</Grid>
	);
}
